'use client'

import { useState, useCallback } from 'react'
import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/navigation'
import { Grid, List, Divider } from '@mui/material'
import { SpaceDashboardTwoTone as SpaceDashboardTwoToneIcon, LogoutTwoTone as LogoutTwoToneIcon } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'

// Types
import type { APIUserLogoutReadResult } from '@/types/api/(user)/user/logout'
import type { AsideProps } from '@/types/components/molecules/aside'

// Configurations
import Config from '@/config'

// Helpers
import { useUserStore } from '@/helpers/store/user'

// Components
const MenuMolecule = dynamic(() => import('@/components/molecules/menu'))
const DialogMolecule = dynamic(() => import('@/components/molecules/dialog'))
const ListItemAtom = dynamic(() => import('@/components/atoms/items/list'))
const LogoAtom = dynamic(() => import('@/components/atoms/shapes/logo'))

const AsideMolecule = (props: AsideProps) => {
	// Props
	const { lng, open } = props

	// Variables
	const router = useRouter()
	const { reset } = useUserStore()
	const [dialog, setDialog] = useState<boolean>(false)

	// Callbacks
	const logoutRequest = useCallback(async () => {
		// Response
		const res = await fetch('/api/user/logout', {
			method: 'GET',
			headers: { 'Accept-Language': lng }
		})

		// Result
		const data: APIUserLogoutReadResult = await res.json()

		// Check result
		if (data.status) {
			// Remove cookies
			Cookies.remove(`${Config.shortName}-token`)
			Cookies.remove(`${Config.shortName}-access-admin`)

			// Reset user store
			reset()

			// Redirect
			router.push(`/${lng}/login`)
		}

		// Check messages
		if (data.message) enqueueSnackbar(data.message, { variant: data.status ? 'success' : 'error', autoHideDuration: 6000 })
		if (data.messages) data.messages.map(message => enqueueSnackbar(message, { variant: data.status ? 'success' : 'error', autoHideDuration: 6000 }))
	}, [lng, router, reset])

	return (
		<Grid
			container
			minHeight={{ xs: 'calc(100% - 56px)', sm: 'calc(100% - 64px)' }}
			sx={{
				'& .MuiList-root': {
					display: 'inline-flex',
					flexDirection: 'column',
					gap: 0.5,
					width: 1,
					'& .MuiCollapse-root': {
						p: 0.5,
						bgcolor: 'action.hover',
						borderRadius: 1.25
					},
					'& a': { color: 'inherit' }
				}
			}}
		>
			<Grid size={12}>
				<List>
					<ListItemAtom lng={lng} href={`/${lng}`} text="common:link.dashboard" icon={<SpaceDashboardTwoToneIcon />} onlyIcon={!open} />
					<MenuMolecule lng={lng} />
				</List>
			</Grid>

			<Grid alignSelf="end" size={12}>
				<Divider />

				{open && (
					<>
						<List>
							<ListItemAtom lng={lng} href="#" text="common:link.logout" icon={<LogoutTwoToneIcon />} handleClick={() => setDialog(true)} />
						</List>

						<Divider />
					</>
				)}

				<List>
					<ListItemAtom lng={lng} href="https://regiti.com" text="common:link.regiti" icon={<LogoAtom width={24} height={24} />} onlyIcon={!open} target="_blank" />
				</List>
			</Grid>

			<DialogMolecule
				lng={lng}
				title="common:title.warning"
				description="common:description.logout"
				confirm="common:title.logout"
				close="common:title.cancel"
				open={dialog}
				setOpen={setDialog}
				confirmAction={() => logoutRequest()}
			/>
		</Grid>
	)
}

export default AsideMolecule
